import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActionSheetController, AlertController } from '@ionic/angular';
import { Router } from '@angular/router';


import { AsistenciaService } from 'src/app/services/asistencia.service';
import { AlertService } from 'src/app/services/alert.service';
import { UserService } from 'src/app/services/user.service';

import * as moment from 'moment';

@Component({
  selector: 'app-card-user',
  templateUrl: './card-user.component.html',
  styleUrls: ['./card-user.component.scss'],
})
export class CardUserComponent implements OnInit {

  @Input() user;
  @Output() deleteUser: EventEmitter<any> = new EventEmitter();
  @Output() disableUser: EventEmitter<any> = new EventEmitter();

  constructor(
    private actionSheetController: ActionSheetController,
    private asistenciaService: AsistenciaService,
    private alertController: AlertController,
    private alertService: AlertService,
    private userService: UserService,
    private router: Router
  ) { }

  ngOnInit() {}

  ngOnChanges(changes) {
    if (changes.user) this.user = changes.user.currentValue;
  }

  async checkPreviousPayment() {
    if (this.user.hasPreviousPayment) {
      const alert = await this.alertController.create({
        header: 'Pago anterior detectado',
        subHeader: `¿Desea cobrar el mismo importe?`,
        buttons: [
          {
            text: 'Si',
            handler: () => this.repeatLastPayment()
          },
          {
            text: 'No',
            handler: () => this.redirectTo('/admin/pagos')
          },
          {
            text: 'Cancelar',
            role: 'cancel',
            handler: () => {}
          }
        ]
      })

      return await alert.present();
    }

    this.redirectTo('/admin/pagos');
  }

  async showOptions() {
    const sheet = await this.actionSheetController.create({
      buttons: [
        {
          icon: 'pencil-outline',
          text: 'Editar',
          handler: () => this.redirectTo('/tabs/detalle-usuario')
        },
        {
          icon: 'cash-outline',
          text: 'Confirmar pago',
          handler: () => this.checkPreviousPayment()
        },
        {
          icon: 'archive-outline',
          text: this.user.estado ? 'Desactivar acceso' : 'Activar acceso',
          handler: () => this.disableUser.emit(this.user)
        },
        {
          icon: 'trash-outline',
          text: 'Eliminar usuario',
          handler: () => this.deleteUser.emit(this.user)
        },
      ]
    });

    return await sheet.present();
  }

  async confirmAssist(user) {
    const alert = await this.alertController.create({
      header: 'Confirmar asistencia',
      subHeader: `¿Estas seguro de confirmar la asistencia de ${user.name}?`,
      buttons: [
        {
          text: 'Aceptar',
          handler: () => this.createAssist(user)
        },
        {
          text: 'Cancelar',
          role: 'cancel',
          handler: () => {}
        }
      ]
    })

    return await alert.present();
  }

  async createAssist(user) {
    const data = {
      id_users: user.id,
      fecha: moment().format(),
      suc_id_sucursal: user.suc_id_sucursal
    }

    const response: any = await this.asistenciaService.crear(data);
    if (response.success) {
      user.assistedToday = true;
      this.alertService.toast('Asistencia enviada correctamente');
    } else {
      this.alertService.toast('No se ha podido registrar la asistencia');
    }
  }

  async repeatLastPayment() {
    const data = {
      userId: this.user.id,
    }

    const response: any = await this.userService.repeatLastPayment(data);
    if (response.success) {
      this.user.pagado = 1;
      this.alertService.toast('Pago registrado correctamente');
    } else {
      this.alertService.toast('No se ha podido registrar el pago');
    }
  }

  redirectTo(route) {
    this.router.navigate([route], { queryParams: { usuario: this.user.id }});
  }

}
